import React from "react";
import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout>
    <p>Yo</p>
  </Layout>
);

export default NotFoundPage;
